import Vue from 'vue';
import { format, parseISO } from 'date-fns';

Vue.filter('formatDate', (value, filterFormat) => {
  if (value) {
    if (value instanceof Date) {
      return format(value, filterFormat || 'MMM dd, yyyy');
    } else {
      return format(parseInt(value), filterFormat || 'MMM dd, yyyy');
    }
  }

  return '';
});
