import icons from './icons';
import theme from './theme';
import analytics from './analytics';
import { version } from '../../package.json';
export default {
  // product display information
  product: {
    name: 'My Singular Health',
    version: version,
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // analytics configs
  analytics,
};
