import Vue from 'vue';
import Router from 'vue-router';
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}
// Vue.use(Router);

export const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Home.vue'),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: '',
            name: 'dashboard-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Home.vue'),
          },
          {
            path: 'firm',
            name: 'firm-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Firm.vue'),
          },
          {
            path: 'about',
            name: 'about-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/About.vue'),
          },
          {
            path: 'compliance',
            name: 'compliance-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Compliance.vue'),
          },
          {
            path: 'consulting',
            name: 'consulting-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Consulting.vue'),
          },
          {
            path: 'pricing',
            name: 'pricing-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Pricing.vue'),
          },
          {
            path: 'links',
            name: 'links-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Links.vue'),
          },
          {
            path: 'contact',
            name: 'contact-page',
            component: () => import(/* webpackChunkName: "login-page" */ '@/pages/Dashboard/Contact.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-__tests__" */ '@/layouts/ErrorLayout.vue'),
    children: [
      {
        path: '',
        name: 'error',
        component: () => import(/* webpackChunkName: "__tests__" */ '@/pages/error/NotFoundPage.vue'),
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next();
});

/**
 * After each route update
 */
router.afterEach((to, from) => {});

export default router;
