export default {
  // global theme for the app
  globalTheme: 'light', // light | dark
  // dark theme colors
  dark: {
    textbase: '#edf0f2',
    background: '#05090c',
    surface: '#111b27',
    primary: '#0096c7',
    secondary: '#5c7f94',
    accent: '#59bbbb',
    error: '#FF5252',
    info: '#2196F3',
    success: '#05c075',
    warning: '#FFC107',
  },

  // light theme colors
  light: {
    textbase: '#222222',
    background: '#ffffff',
    surface: '#f2f5f8',
    primary: '#2a83f8',
    secondary: '#12279b',
    accent: '#59bbbb',
    error: '#ef476f',
    info: '#2196F3',
    success: '#24A0ED',
    warning: '#ffd166',
  },
};
