import Vue from 'vue';
import Vuex from 'vuex';

// Global vuex
import AppModule from './app';
import ErrorModule from './error';

Vue.use(Vuex);

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    error: ErrorModule,
  },
});

export default store;
